import "@fontsource-variable/nunito";
import "@fontsource-variable/fira-code";

import { theme } from "./utils/theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import Home from "./components/Home";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Home />
    </ThemeProvider>
  );
}

export default App;
