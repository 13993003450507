import { Box, Typography, useMediaQuery } from "@mui/material";
import Image1 from "../assets/number66.jpg";
import Image2 from "../assets/number10.png";
import Image3 from "../assets/number5.jpeg";
import Image4 from "../assets/number01.jpg";
import Image5 from "../assets/number81.jpg";
import styled from "styled-components";
import { theme } from "../utils/theme";

const TextContainer = styled("div")({
  display: "block",
  unicodeBidi: "isolate",
  backgroundColor: theme.palette.tertiary.main,
  color: theme.palette.secondary.main,
  padding: "4rem 10%",
  textAlign: "justify",
});

const ImageComponent = () => {
  const mobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div
      style={{
        display: "block",
        unicodeBidi: "isolate",
      }}
    >
      <Box
        sx={{
          position: "relative",
          backgroundAttachment: "fixed",
          backgroundPositionX: "left",
          backgroundPositionY: 60,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          minHeight: "100vh",
          backgroundImage: `url(${Image1})`,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h1"
          sx={{
            letterSpacing: "0.5rem",
            fontFamily: "Fira Code Variable",
            color: "common.white",
            padding: "0.5rem 1rem",
            fontWeight: "bold",
          }}
        >
          Nora Starr
        </Typography>
        <Typography
          variant="h2"
          sx={{
            letterSpacing: "0.5rem",
            fontFamily: "Nunito Variable",
            color: "common.white",
            padding: "0.5rem 1rem",
            fontWeight: "bold",
            fontSize: "1.25rem",
          }}
        >
          Independent BBW Escort Zurich
        </Typography>
      </Box>
      <TextContainer
        id="#about"
        style={{
          backgroundColor: theme.palette.secondary.main,
          color: theme.palette.tertiary.main,
        }}
      >
        <Typography variant="h2" textAlign="center" mb="2rem">
          About me
        </Typography>
        <Typography
          variant="h4"
          lineHeight="180%"
          sx={{
            whiteSpace: "pre-wrap",
          }}
        >
          Hi, I am Nora❣
          {"\n"}❥ I am a <b>strong and dominant woman</b> by nature.
          {"\n"}❥ By day I have a full time job as a Software Developer, by
          night <b> I like to explore fantasies</b>.{"\n"}❥ Born in{" "}
          <b>Brazil</b> and raised in <b>Europe</b>, I carry a blend of cultures
          that makes me an intriguing acquaintance.
          {"\n"}❥ In my free time, I love exploring new places and{" "}
          <b>connecting with people</b>, immersing myself in the thrill of being
          alive.
          {"\n"}❥ My figure is <b>full, voluptuous, and captivating</b> - a
          perfect mix of strength, softness, sensuality, and playfulness. My
          personality is
          <b>open minded, flirty, funny, and smart</b>.{"\n"}❥ I enjoy making
          people laugh, challenging their beliefs, and leaving them even more
          curious about life. With my experience, I can adapt to whatever brings
          pleasure and satisfaction to others. From the most{" "}
          <b>innocent encounters</b> to <b>BDSM</b>* or unconventional{" "}
          <b>fantasies</b> - I have explored a lot and am always eager to
          discover more.
          {"\n"}
          {"\n"}* BDSM is only possible with me in the dominant role.
          {"\n"}
        </Typography>
      </TextContainer>
      <div
        style={{
          position: "relative",
          backgroundAttachment: "fixed",
          backgroundPositionX: "center",
          backgroundPositionY: "30%",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          minHeight: "100vh",
          backgroundImage: `url(${Image2})`,
          opacity: 0.6,
        }}
      />
      <TextContainer>
        <Typography
          variant="h4"
          lineHeight="2rem"
          textAlign="left"
          sx={{
            whiteSpace: "pre-wrap",
          }}
        >
          ★ Domicile - Zurich, Switzerland
          {"\n"}★ Age - 33
          {"\n"}★ Height - 177 cm
          {"\n"}★ Weight - 105 kg
          {"\n"}★ Body type - Full figure (BBW - Big Beautiful Woman)
          {"\n"}★ Shoe size - 39/40
          {"\n"}★ Hair color - Colorful (usually pink, purple or blue)
          {"\n"}★ Eye color - Brown
          {"\n"}★ Tattoo - Yes
          {"\n"}★ Piercing - Yes
          {"\n"}★ Style - Alternative, classy
          {"\n"}★ No fillers, silicone, or any other chirurgical procedure
          {"\n"}★ Smoker - No
        </Typography>
      </TextContainer>
      <div
        style={{
          position: "relative",
          backgroundAttachment: "fixed",
          backgroundPositionX: "center",
          backgroundPositionY: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: mobile ? "100%" : "50%",
          minHeight: "100vh",
          backgroundImage: `url(${Image3})`,
          opacity: 0.8,
          filter: "blur(5px)",
          backgroundColor: theme.palette.primary.main,
        }}
      />
      <TextContainer
        id="#explore"
        style={{
          backgroundColor: theme.palette.secondary.main,
          color: theme.palette.tertiary.main,
        }}
      >
        <Typography variant="h2" textAlign="center" mb="2rem">
          Explore
        </Typography>
        <Typography
          variant="h4"
          lineHeight="2rem"
          sx={{
            whiteSpace: "pre-wrap",
          }}
          textAlign="center"
        >
          <b>Exploring different kinks and fantasies</b> has been quite the
          journey for me. There is something refreshing about diving into new
          experiences and discovering what really excites you.
          {"\n"}
          {"\n"}︵‿︵‿୨♡୧‿︵‿︵{"\n"}
          {"\n"}One experience I enjoyed the most was having him{" "}
          <b>wrapped tightly in cling film, completely at my mercy</b> —
          helpless beneath me. I walk over him, each step a reminder of my
          control, savoring the thrill of power. When I settle down,{" "}
          <b>taking my seat on his face</b>, it is with a quiet, commanding
          confidence. He is mine, and I revel in the delicious mix of dominance
          and submission, knowing <b>I am fully in control</b>. {"\n"}
          {"\n"}»--•--«
          {"\n"}
          {"\n"}I have embraced the idea of <b>
            being in complete control, not through force, but with pure presence
            and allure
          </b>
          . Imagine it: having someone eager to fulfill your every whim, <b>not because they have to, but because they want to</b>. It is all
          about subtle power, the kind that comes from confidence and charm.
          There is something intoxicating about the way they hang on your every
          word, anticipating your desires without a single command. It is about
          owning the moment with grace and poise, knowing the influence you hold
          and reveling in that quiet, <b>irresistible dominance</b>.{"\n"}
          {"\n"}»--•--«{"\n"}
          {"\n"}One of the more unusual experiences I have explored is "vore."
          Vore fantasies center around the idea of being swallowed whole by
          another person, in a metaphorical or fantastical sense. It is not
          about harm, but rather the{" "}
          <b>intense concept of complete surrender</b> — of being emotionally
          and physically consumed by someone else. There is something strangely
          captivating about this ultimate form of submission, despite how
          surreal it may seem. It's a kink that thrives in the realm of pure
          imagination, making it one of the most bizarre, yet oddly compelling,
          fantasies I have encountered.
          {"\n"}
          {"\n"}»--•--«{"\n"}
          {"\n"}
          <strong>
            Whether you are into feet, giantess, BDSM*, sci-fi, or any other
            kink
          </strong>
          , I am excited to explore it all with you! Lets dive into your desires
          and discover new experiences together!
          {"\n"}
          {"\n"}
          {"\n"}* BDSM is only possible with me in the dominant role.
          {"\n"}
        </Typography>
      </TextContainer>
      <div
        style={{
          position: "relative",
          backgroundAttachment: "fixed",
          backgroundPositionX: "center",
          backgroundPositionY: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: mobile ? "100%" : "80%",
          minHeight: "100vh",
          backgroundImage: `url(${Image4})`,
          backgroundColor: theme.palette.primary.main,
        }}
      />
      <TextContainer id="#booking">
        <Typography variant="h2" textAlign="center" mb="2rem">
          Rates
        </Typography>
        <Typography
          variant="h4"
          lineHeight="2rem"
          sx={{
            whiteSpace: "pre-wrap",
          }}
        >
          » I offer only <b>outcalls</b>. I visit only hotels, no private
          apartments or villas.
          {"\n"}» I am only available by prearranged meetings with Gentlemen. I
          will only consider serious and respectful inquiries, do not waste my
          time please.
          {"\n"}» I offer and ask <b>total discretion</b> during our meetings.
          {"\n"}» I am available and always delighted to meet <b>couples</b>.
          {"\n"}» Please do not contact me for last-minute impersonal 1 hour
          dates - I am not in waiting mode for something not meaningful.
          {"\n"}» I prefer to be contacted by email first, but will not respond
          to 1-2 sentences enquiries.{" "}
          <b>
            Be reasonable with your offer and write as much details as you can
          </b>
          , please.
          {"\n"}» Please hand me over the{" "}
          <b>
            reimbursement discreetly in an open envelope at the beginning of the
            date
          </b>{" "}
          in order to get the business part out of the way and enjoy our time
          together. My rates <b>are absolutely not up for negotiation</b>.
          Please do not insult me by trying to negotiate.
          {"\n"}
          {"\n"}
          {"\n"}★ Sweet Bite <b> (2h) * 600 CHF/EUR </b>
          {"\n"}★ Sugar Rush <b> (3h) * 800 CHF/EUR </b>
          {"\n"}★ Sweetest Pie <b> (5h) * 1000 CHF/EUR </b>
          {"\n"}★ Candy Wonderland <b> (16h) * 1600 CHF/EUR</b>
          {"\n"}★ Chocolate Dream <b> (24h) * 2100 CHF/EUR </b>
          {"\n"}★ Sugar Overload <b> (48h) * 3400 CHF/EUR </b>
          {"\n"}
          {"\n"}Lets start with a glass of wine and a nice conversation — I want
          to get inside your head and make your fantasies come true. Tell me
          about your perfect date, and we will figure out the details!
        </Typography>
      </TextContainer>
      <div
        style={{
          position: "relative",
          backgroundAttachment: "fixed",
          backgroundPositionX: "center",
          backgroundPositionY: "top",
          backgroundRepeat: "no-repeat",
          minHeight: "100vh",
          backgroundImage: `url(${Image5})`,
        }}
      />
      <TextContainer
        id="#contact"
        style={{
          backgroundColor: theme.palette.secondary.main,
          color: theme.palette.tertiary.main,
        }}
      >
        <Typography variant="h2" textAlign="center" mb="2rem">
          Contact
        </Typography>
        <Typography
          variant="h4"
          textAlign="center"
          lineHeight="2rem"
          fontStyle="italic"
          mb="2rem"
        >
          Voulez-vous coucher avec moi?
        </Typography>
        <Typography
          variant="h4"
          lineHeight="2rem"
          sx={{
            whiteSpace: "pre-wrap",
          }}
        >
          Please send me an{" "}
          <b>
            introduction of yourself, your desired time frame, the duration and
            the hotel for the date
          </b>
          . I am looking forward to have fun with you! :)
          {"\n"}
          {"\n"}
        </Typography>
        <Typography
          variant="h4"
          lineHeight="2rem"
          sx={{
            overflowWrap: "break-word",
            wordWWrap: "break-word",
            textAlign: "left",
          }}
        >
          ⌨ Email: <b>nora.escort.zurich@gmail.com</b>
        </Typography>
      </TextContainer>
      <Typography variant="h5" textAlign="center" py="2%">
        {new Date().getFullYear()} © Nora Starr
      </Typography>
    </div>
  );
};

export default ImageComponent;
