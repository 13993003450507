import { Box } from "@mui/material";
import ImageComponent from "./ImageComponent";
import TopBar from "./TopBar";

const Home = () => {
  return (
    <>
      <TopBar />
      <ImageComponent />
    </>
  );
};

export default Home;
